import React, { useState, useEffect } from 'react';
import { Link, navigate } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import CustomFetch from '../components/fetch';
import CustomMessage from '../components/message';
import { Ticket, ChevronRight } from 'akar-icons';
import scrollTo from 'gatsby-plugin-smoothscroll';
import parse from 'html-react-parser';

const TicketPage = ({ location }) => {
    const [messageSetting, setMessageSetting] = useState({
        showMessage: false,
        messageClass: 'dialog dialog--error',
        messageContent: '',
    });
    const [data, setData] = useState();
    const [messages, setMessages] = useState([]);
    const [reply, setReply] = useState('');

    const handleChange = (e) => {
        if (e) {
            setReply(e.target.value);
        }
    };

    const handleSubmit = (e) => {
        if (e) {
            e.preventDefault();

            if (reply === '') {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: 'Please enter a message to send.',
                }));
            } else {
                CustomFetch(
                    'ticketMessages',
                    'POST',
                    {
                        message: reply,
                        ticket_id: location.state.ticket_id,
                    },
                    function (result) {
                        if (result.error === '') {
                            fetchMessages();
                            setReply('');

                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--success',
                                messageContent:
                                    'You have successfully send a reply.',
                            }));
                        } else {
                            setMessageSetting((prevState) => ({
                                ...prevState,
                                showMessage: true,
                                messageClass: 'dialog dialog--error',
                                messageContent: String(result.error),
                            }));
                        }
                    },
                    function (error) {
                        setMessageSetting((prevState) => ({
                            ...prevState,
                            showMessage: true,
                            messageClass: 'dialog dialog--error',
                            messageContent: error,
                        }));
                    }
                );
            }
        }
    };

    const fetchMessages = () => {
        CustomFetch(
            'ticketMessages/list',
            'POST',
            {
                ticket_id: location.state.ticket_id,
            },
            function (result) {
                setMessages(result);
            },
            function (error) {
                setMessageSetting((prevState) => ({
                    ...prevState,
                    showMessage: true,
                    messageClass: 'dialog dialog--error',
                    messageContent: error,
                }));
            }
        );
    };

    useEffect(() => {
        if (location.state) {
            CustomFetch(
                `tickets/${location.state.ticket_id}`,
                'GET',
                {},
                function (result) {
                    setData(result);
                    fetchMessages();
                },
                function (error) {
                    setMessageSetting((prevState) => ({
                        ...prevState,
                        showMessage: true,
                        messageClass: 'dialog dialog--error',
                        messageContent: error,
                    }));
                }
            );
        } else {
            navigate('/');
        }
    }, []);
    return (
        <Layout title="Ticket Overview">
            <Seo title="Ticket" description="Dawn Projects Ticket System" />
            <CustomMessage data={messageSetting} />
            <div className="wrap">
                <div
                    className="tickets pad"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <Link
                        to="/"
                        data-sal="slide-down"
                        data-sal-duration="500"
                        data-sal-easing="ease-in-out-quart"
                    >
                        <span className="ticket ticketopen">
                            <span className="ticket__top">
                                <h3>Ticket No. {data ? data.id : null}</h3>
                                <small
                                    className={
                                        data
                                            ? data.status_id === 3
                                                ? 'pending'
                                                : data.status_id === 2
                                                ? 'recent'
                                                : ''
                                            : ''
                                    }
                                >
                                    {data ? data.status.label : null}{' '}
                                    <Ticket size={20} />
                                </small>
                                <p>
                                    <strong>CAI:</strong>{' '}
                                    {data ? data.cai : null}
                                </p>
                                <p>
                                    <strong>Building:</strong>{' '}
                                    {data
                                        ? data.building
                                            ? data.building.label
                                            : null
                                        : null}
                                </p>
                                <p>
                                    <strong>Priority:</strong>{' '}
                                    {data
                                        ? data.priority
                                            ? data.priority.label
                                            : null
                                        : null}
                                </p>
                                <p>
                                    <strong>Category:</strong>{' '}
                                    {data
                                        ? data.subcategory
                                            ? data.subcategory.category.label
                                            : null
                                        : null}
                                    {data
                                        ? data.subcategory
                                            ? ' - ' + data.subcategory.label
                                            : null
                                        : null}
                                </p>
                                <p>
                                    <strong>Location of Issue:</strong>{' '}
                                    {data ? data.location_of_issue : null}
                                </p>
                                <p>{data ? data.description : null}</p>
                            </span>
                        </span>
                    </Link>
                </div>
                <div
                    className="messages"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                >
                    <div className="message">
                        <span>Latest Message Update</span>
                        {messages.length > 0
                            ? parse(messages[messages.length - 1].message)
                            : null}
                    </div>
                    <div className="message__reply">
                        <button
                            type=""
                            className="btn"
                            onClick={() => scrollTo('#mscroll')}
                        >
                            Reply to Message <ChevronRight size={24} />
                        </button>
                    </div>
                </div>
                <div className="breaktitle">
                    <h2>Messages</h2>
                </div>
                <div
                    className="messages"
                    data-sal="slide-down"
                    data-sal-duration="500"
                    data-sal-easing="ease-in-out-quart"
                    id="mscroll"
                >
                    {messages.length > 0
                        ? messages.map((a) => (
                              <div className="message">
                                  <span>{a.user}</span>
                                  {parse(a.message)}
                              </div>
                          ))
                        : null}
                    <div className="message__reply">
                        <div className="formitem fw">
                            <label className="fi__label">
                                <textarea
                                    name="reply"
                                    value={reply}
                                    onChange={handleChange}
                                    type="textarea"
                                    placeholder="Type your message"
                                    rows="10"
                                    className="message__box"
                                />
                            </label>
                        </div>
                        <button onClick={handleSubmit} className="btn">
                            Send <ChevronRight size={24} />
                        </button>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default TicketPage;
